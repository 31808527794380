<script>
  import linkMixin from '@/mixins/linkMixin'
  import screen from '@/helpers/screen'

  /**
   * A button with a label and optionally an icon.
   */
  export default {
    mixins: [linkMixin],
    inheritAttrs: false,
    props: {
      /** The button’s type. When left `undefined`, the type is determined automatically (`link` if there is a `to` or `href`, `button` otherwise). Use `submit` when a button’s purpose is to submit a form. Note that a `submit` button must be inside a `BaseForm` component, and its `click` event is automatically triggered when the user presses Enter while an input of the form is focused. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['button', 'submit', 'link'].includes(value),
      },
      /** The height of the button. */
      height: {
        type: String,
        default: 'normal',
        validator: (value) => ['shorter', 'short', 'normal'].includes(value),
      },
      /** The background color of the button. */
      color: {
        type: String,
        default: 'primary',
        validator: (value) =>
          [
            'primary',
            'primary-dawn',
            'primary-alt',
            'summer',
            'secondary',
            'sunshine',
            'black',
            'white',
            'spring',
          ].includes(value),
      },
      /** Whether the button is filled with color (`true`) or just the outline (`false`). */
      outlineOnly: {
        type: Boolean,
        default: false,
      },
      /** The position of the icon relative to the label. */
      iconPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value),
      },
      /** The size of the icon, in Tailwind units. */
      iconSize: {
        type: Number,
        default: 6,
      },
      /** Whether the button is disabled. A disabled button is grayed out and cannot be focused or clicked. */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
      finalType() {
        if (this.type === undefined) {
          if (this.hasLink) {
            return 'link'
          } else {
            return 'button'
          }
        }
        return this.type
      },
      component() {
        switch (this.finalType) {
          case 'button':
          case 'submit':
            return 'button'
          case 'link':
            return 'BaseLink'
          default:
            return undefined
        }
      },
      propsToPassDown() {
        switch (this.finalType) {
          case 'button':
          case 'submit':
            return {
              type: this.finalType,
              disabled: this.disabled,
            }
          case 'link':
            if (this.disabled) {
              return {}
            }
            return this.linkProps
          default:
            return {}
        }
      },
      classes() {
        // for purgecss: button--primary button--primary-alt button--summer button--secondary button--sunshine button--black button--white button--spring button--desktop button--disabled
        return [
          'button',
          `button--${this.color}`,
          this.disabled ? 'button--disabled' : '',
          this.outlineOnly ? 'button--outline' : '',
          this.screen.md ? 'button--desktop' : '',
          this.height === 'normal' ? 'min-h-15' : '',
          this.height === 'short' ? 'min-h-12' : '',
          this.height === 'shorter' ? 'px-10px py-1' : 'px-6 py-4',
          'flex',
          'justify-center',
          'items-center',
          'w-full',
          'border',
          'font-medium',
          'text-center',
          'leading-tight',
          'transition',
          'active:transition-none',
          'duration-200`',
        ].join(' ')
      },
    },
  }
</script>

<template>
  <div>
    <component
      :is="component"
      :class="classes"
      v-bind="{ ...propsToPassDown, ...$attrs }"
      v-on="$listeners"
    >
      <BaseIcon
        v-if="$scopedSlots.icon && iconPosition === 'left'"
        :size="iconSize"
        class="mr-2 flex-shrink-0"
      >
        <!-- @slot Optional. An icon shown next to the button’s label. Should contain an SVG icon component. -->
        <slot name="icon" />
      </BaseIcon>
      <span class="block py-1 overflow-hidden ellipsis">
        <!-- @slot The button’s label. Should contain text. -->
        <slot />
      </span>
      <BaseIcon
        v-if="$scopedSlots.icon && iconPosition === 'right'"
        :size="iconSize"
        class="ml-2 flex-shrink-0"
      >
        <slot name="icon" />
      </BaseIcon>
    </component>
  </div>
</template>

<style lang="postcss" scoped>
  .button {
    &--primary {
      &.button--disabled {
        &:not(.button--outline) {
          @apply bg-primary text-white opacity-60 cursor-default;
        }
        @apply border-dawn-dk4 text-dawn-dk4;
      }

      &:not(.button--disabled) {
        @apply border-primary text-primary;

        &:not(.button--outline) {
          @apply bg-primary text-white;

          &:hover {
            @apply bg-primary-bright text-white;
          }

          &:focus {
            @apply bg-primary-light text-white;
          }

          &:active {
            @apply bg-primary-dark text-white;
          }
        }

        &:hover {
          @apply bg-gray-opacity4 text-primary;
        }

        &:focus {
          @apply bg-gray-opacity12 text-primary;
        }

        &:active {
          @apply bg-gray-opacity20 text-primary;
        }
      }
    }

    /* Same as primary except for text color */
    &--primary-dawn {
      &:not(.button--disabled) {
        @apply border-primary text-dawn;

        &:not(.button--outline) {
          @apply bg-primary text-dawn;

          &:hover {
            @apply bg-primary-bright text-dawn;
          }

          &:focus {
            @apply bg-primary-light text-dawn;
          }

          &:active {
            @apply bg-primary-dark text-dawn;
          }
        }

        &:hover {
          @apply bg-gray-opacity4 text-primary;
        }

        &:focus {
          @apply bg-gray-opacity12 text-primary;
        }

        &:active {
          @apply bg-gray-opacity20 text-primary;
        }
      }
    }

    &--primary-alt {
      &:not(.button--disabled) {
        @apply border-primary-alt text-primary-alt;

        &:not(.button--outline) {
          @apply bg-primary-alt text-white;

          &:hover {
            @apply bg-primary-alt-bright text-white;
          }

          &:focus {
            @apply bg-primary-alt border-2 border-primary-alt-darkest text-white;
          }

          &:active {
            @apply bg-primary-alt-dark text-white;
          }
        }
      }
    }

    &--summer {
      &:not(.button--disabled) {
        @apply border-summer text-black;

        &:not(.button--outline) {
          @apply bg-summer text-white;

          &:hover {
            @apply bg-summer-bright text-white;
          }

          &:focus {
            @apply bg-summer border-2 border-summer-darkest text-white;
          }

          &:active {
            @apply bg-summer-dark text-white;
          }
        }
      }
    }

    &--secondary {
      &:not(.button--disabled) {
        @apply border-secondary text-secondary;

        &:not(.button--outline) {
          @apply bg-secondary text-white;

          &:not(.button--desktop) {
            @apply bg-secondary-dark text-white;
          }
        }

        &:focus {
          @apply bg-secondary-darker shadow-outline-secondary-50;
        }

        &:hover {
          @apply bg-secondary-dark text-white;
        }

        &:active {
          @apply bg-secondary-darkest;
        }
      }
    }

    &--sunshine {
      &:not(.button--disabled) {
        @apply border-sunshine text-black;

        &:not(.button--outline) {
          @apply bg-sunshine text-black;
        }

        &:hover {
          @apply bg-sunshine-bright text-black shadow-sm;
        }

        &:focus {
          @apply bg-sunshine-light text-black shadow-outline-dawn-50;
        }

        &:active {
          @apply bg-sunshine-dark text-black;
        }
      }
    }

    &--spring {
      &:not(.button--disabled) {
        @apply border-spring text-black;

        &:not(.button--outline) {
          @apply bg-spring text-black;
        }
      }
    }

    &--black {
      &:not(.button--disabled) {
        @apply border-black text-black;

        &:not(.button--outline) {
          @apply bg-black text-dawn;
        }
      }
    }

    &--white {
      &:not(.button--disabled) {
        @apply bg-white border-primary text-primary;

        &:hover,
        &:focus,
        &:active {
          @apply border-2;
        }

        &:not(.button--outline) {
          @apply bg-white border-none text-primary;
        }
      }
    }

    &--disabled {
      &:not(.button--primary) {
        @apply border-transparent bg-dawn-dk4 text-dawn-lt3 cursor-default;
      }
    }
  }
</style>
